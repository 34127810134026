<template>
  <div class="readme-article">
    <h1 id="系统">5.系统</h1>
    <h2 id="读卡器设置">5.1读卡器设置</h2>
    <p>读卡器设置</p>
    <!-- <p>
      <img src="@/assets/img/recruit/config5-1.png" />
    </p> -->
    <h2 id="校区设置">5.2校区设置</h2>
    <p>校区设置</p>
    <p>
      <img src="@/assets/img/recruit/config5-2.png" />
    </p>
    <h2 id="分公司设置">5.3分公司设置</h2>
    <p>分公司设置</p>
    <p>
      <img src="@/assets/img/recruit/config5-3.png" />
    </p>
    <h2 id="许可IP列表设置">5.4许可IP列表设置</h2>
    <p>许可IP设置</p>
    <p>
      <img src="@/assets/img/recruit/config5-4.png" />
    </p>
    <h2 id="设置系统信息">5.5设置系统信息</h2>
    <p>系统信息参数设置</p>
    <!-- <p>
      <img src="@/assets/img/recruit/config5-5.png" />
    </p> -->
    <h2 id="学生教师平台定制">5.6学生教师平台定制</h2>
    <p>教师学生平台开放设置</p>
    <p>
      <img src="@/assets/img/recruit/config5-6.png" />
    </p>
  </div>
</template>

<script>
export default {
  name: "Recruit0-5",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
</style>